@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family:
    'Pretendard',
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  background: #fff;
}

[hidden] {
  display: none;
}

a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  outline: 0;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #ebebeb;
}

*::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #adadad;
}

.dot-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #adadad;
  border-radius: 100%;
  animation: bounce 1.4s infinite ease-in-out both;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #858583;
  border-top: 2px solid white;
  border-radius: 100%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@layer utilities {
  .no-input-spinner::-webkit-inner-spin-button,
  .no-input-spinner::-webkit-outer-spin-button {
    @apply appearance-none;
    margin: 0;
  }

  .no-input-spinner {
    @apply appearance-none;
  }
}

.dropdown {
  position: absolute;
  top: 14px;
  left: 14px;
  height: 24px;
  z-index: 10;
  background: white;
  padding: 3px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.dropdown-label {
  height: 24px;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  padding-left: 6px;
}

.dropdown-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.dropdown-content {
  padding: 25px;
}

.dropdown-item {
  font-size: 18px;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-item span {
  color: orange;
}

.dropdown-confirm {
  margin-top: 20px;
  width: 100%;
  padding: 15px 0;
  background: #2e2e2e;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.icCheckOrange {
  width: 24px;
  height: 24px;
}

.icChevron {
  width: 24px;
  height: 24px;
}
